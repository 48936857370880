
import * as React from "react"
import "../css/animate.css"
import "../css/bootstrap-grid.css"
import "../css/bootstrap.min.css"
import "../css/dark-color.css"
import "../css/style.css"
import "../css/responsive.css"
import logo from "../logo/logo-transperent.png"
import manWalkingIMage from "../images/stock/man-walking.jpg"
import influCoinImage from "../images/stock/influcoin-splash.jpg"
import {Helmet} from "react-helmet";

// markup
const FinnishPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>White Bear</title>
        <link rel="canonical" href="http://www.whitebear.fi" />
        <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css"/>
      </Helmet>
      <main >
        <div className="wrapper ms-overlay no-fixing">

          <section>
            <div className="block">
              <div className="container">
                <div className="hero-wrapper">
                  <img src={logo} className="wb-logo" /><br />
                  <div className="hero-text-wrapper">
                    <h4>We’re <span>WHITE BEAR</span> and we</h4>
                    <h2>help companies succeed on blockchain</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="block no-padding">
              <div className="konst-main-sec">
                <div className="container">
                  <div className="main-construct-sec">
                    <div className="intro-kons">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="cons-img">
                            <img src={manWalkingIMage} alt="man walking across road" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="about-kons">
                            <div className="kn-title">
                              <h3>Why White Bear<br /> was founded in the first place</h3>
                            </div>
                            <h4>3rd January 2009, the Bitcoin network came into existence, ever since then people have been observing the development, 30th July 2015 Ethereum launched with new application of Blockchain.</h4>
                            <p>You may have heard of <span>NFT's, Cryptocurrencies, and blockchain</span> as a whole. However most people are still unsure what kind of impact (if any) these new developments will have on our lives. </p>
                            <p>The <span>Ethereum blockchain</span> further expanded blockchain with smart contracts, enabling logic to be handled on the blockchain enabling what is known as decentralized apps which drastically increased the potential use cases of blockchain.</p>
                            <p>With all new technology comes uncertainty, Blockchain is still in its infancy, in the 90s people were unsure where the internet would lead us, today we take it for granted. We at <span>White Bear</span> strongly believe that Blockchain will be just as widely adopted as the internet is today.</p>
                            <div className="our-projects-counter hidden">
                              <ul>
                                <li>
                                  <h2>24</h2>
                                  <h4>Years <br /> Experience</h4>
                                </li>
                                <li>
                                  <h2>865</h2>
                                  <h4>Projects <br /> Completed</h4>
                                </li>
                                <li>
                                  <h2>4200</h2>
                                  <h4>Workers <br /> Employed</h4>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="our-services style2">
                      <div className="kn-title">
                        <h3>Our Focus Areas </h3>
                      </div>
                      <div className="srvcs">
                        <div className="row">
                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                            <div className="service-details">
                              <div className="service-info">
                                <div className="srvc-icon">
                                  <span className="lnr lnr-cart"></span>
                                  <h3>Crypto payment enablement</h3>
                                </div>
                                <p>Allow your customers to pay you in crypto enables a fast and secure payment for your company.</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                            <div className="service-details">
                              <div className="service-info">
                                <div className="srvc-icon">
                                  <span className="lnr lnr-layers"></span>
                                  <h3>Decentralized apps &amp; NFT's </h3>
                                </div>
                                <p>Ethereum Smart Contract based solutions enabling anything from asset management to fully decentralized apps </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                            <div className="service-details">
                              <div className="service-info">
                                <div className="srvc-icon">
                                  <span className="lnr lnr-chart-bars"></span>
                                  <h3>Tokenized Crowd Funding</h3>
                                </div>
                                <p>Tokenize your company and conquer your industry through ICO's we help companies adapt to blockchain and enable massive funding through an ICO </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="project-gallery">
                      <div className="kn-title pd">
                        <h3>Projects That We<br /> Completed</h3>
                      </div>
                      <div className="pro-gallery">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="gallery-img">
                              <img src="http://via.placeholder.com/685x685" alt="" />
                              <div className="gallery-text left-more">
                                <h5>Buildings</h5>
                                <h3>Mercedes-Benz Musem</h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row no-margin">
                              <div className="col-lg-12">
                                <div className="gallery-img">
                                  <img src="http://via.placeholder.com/685x343" alt="" />
                                  <div className="gallery-text">
                                    <h5>Commercials</h5>
                                    <h3>Heydar Aliyev Center in Baku, Azerbaijan</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="gallery-img">
                                  <img src="http://via.placeholder.com/343x343" alt="" />
                                  <div className="gallery-text">
                                    <h5>Residentials</h5>
                                    <h3>Alex’s Villa</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="gallery-img">
                                  <img src="http://via.placeholder.com/343x343" alt="" />
                                  <div className="gallery-text">
                                    <h5>Buildings</h5>
                                    <h3>Up-Co Working Office Building</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trust-section">
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="trust-col">
                            <div className="kn-title ta-right">
                              <h3>Ongoing projects <br /> and Clients</h3>
                            </div>
                            <div className="rating-star">
                              <h3>Influcoin</h3>
                              <p>Ethereum based blockchain solution which tackles the challenges and opportunties in the influencer market, a <b>13.8 Billion $</b> Market</p>
                              <a href="#" title=""><img src="images/resources/trust-img.png" alt="" /></a>
                            </div>
                            <div className="trust-text hidden">
                              <h2>Excellent service, efficient and left everywhere spotless ! Thank
                                you</h2>
                              <a href="#" title="">- Paul Murren</a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="post-gal">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="post">
                                  <div className="post-img">
                                    <img src={influCoinImage} alt="" />
                                  </div>
                                  <div className="post-details">
                                    <ul className="post-info">
                                      <li><span>Aug 30,2021</span></li>
                                      <li><a href="#" title="">Project</a></li>
                                    </ul>
                                    <h3>Influcoin, the blockchain future of the Influencer industry</h3>
                                    <p>The Influcoin and its associated blockchain technology will help tackle many of the industry’s pinch points with the eventual aim of it evolving into world's central bank for influencers.</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 hidden">
                                <div className="post-sm-sec">
                                  <div className="row">
                                    <div className="col-xl-6 col-lg-12">
                                      <div className="post-details">
                                        <ul className="post-info">
                                          <li><span>Jun 20,2017</span></li>
                                          <li><a href="#" title="">LoganCee</a></li>
                                        </ul>
                                        <h3><a href="#" title="">Top 10 Architecture
                                          Buildings Unique In China</a></h3>
                                        <a href="#" title="">full article <i
                                          className="fa fa-angle-right"></i></a>
                                      </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12">
                                      <div className="post-details">
                                        <ul className="post-info">
                                          <li><span>Jun 20,2017</span></li>
                                          <li><a href="#" title="">LoganCee</a></li>
                                        </ul>
                                        <h3><a href="#" title="">Morroco Shopping Center by
                                          KONS Co.</a></h3>
                                        <a href="#" title="">full article <i
                                          className="fa fa-angle-right"></i></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <footer className="no-fix">
            <div className="footer-style2">
              <div className="container">
                <div className="footer-text">
                  <h5>let’s work together</h5>
                  <h2>(+358) Put a real nuber here</h2>
                  <span>Helsinki, Finland</span>
                  <span>info@whitebear.fi</span>
                </div>
              </div>
            </div>
            <div className="bottom-strip">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="footer-logo">
                      <a href="#" title=""><img src="images/logo.png" alt="" /></a>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="copyright-text white text-center">
                      <p>© 2021 White Bear <br/> by <b>Sterly Oy</b></p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="social-links">
                      <ul>
                        <li><a href="#" title=""><i className="ion-social-facebook"></i></a></li>
                        <li><a href="#" title=""><i className="ion-social-twitter"></i></a></li>
                        <li><a href="#" title=""><i className="ion-social-googleplus"></i></a></li>
                        <li><a href="#" title=""><i className="ion-social-rss"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  )
}

export default FinnishPage
